import React from 'react'
import * as S from './styles'
import { ISendDataLayerProps } from '../../types'

const SuaEmpresaNaoTemLimiteDeCredito = ({ sendDatalayerEvent }: ISendDataLayerProps) => {
  const anchorHref = 'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.2065579558.1674475077&_ga=2.203481110.764229343.1684162555-2065579558.1674475077'
  return (
    <S.Section
      id='chegou-o-programa-de-pontos-pj'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center'>
            <h2 className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 fw-600 text-white mb-3 mb-lg-4 mb-xl-3'>Sua empresa não tem limite de crédito pré-aprovado?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-white mb-0'>Invista no CDB Mais Limite e transforme o valor aplicado em limite no cartão de crédito automaticamente</p>
            <a
              href={anchorHref}
              target='_blank'
              rel='noreferrer'
              title='Quero Inter Loop na minha empresa'
              className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none mb-0'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click scroll',
                  element_name: 'Quero Inter Loop na minha empresa',
                  section_name: 'Sua empresa não tem limite de crédito pré-aprovado?',
                  redirect_url: anchorHref,
                })
              }}
            >
              Acumular pontos Loop
            </a>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SuaEmpresaNaoTemLimiteDeCredito
