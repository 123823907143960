import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import ScrollTo from 'src/components/ScrollTo'

import * as S from './styles'
import { ISendDataLayerProps } from '../../types'
import ImageWebp from 'src/components/ImageWebp'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

const HeroLoopPJ = ({ sendDatalayerEvent }: ISendDataLayerProps) => {
  const data = usePageQuery()
  const width = useWidth(300)
  const ctaUrl = 'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.2065579558.1674475077&_ga=2.203481110.764229343.1684162555-2065579558.1674475077'
  return (
    <S.Section
      id='chegou-o-programa-de-pontos-pj'
      className='d-md-flex align-items-md-center py-5'
      role='img'
      aria-label='Programa de Pontos para Empresas Inter Loop'
    >
      <div className='container'>
        <div className='row align-items-md-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <Img fluid={data.logoLoopPJ.fluid} alt='Logo Programa de Pontos Inter Loop' className='mb-2' />
            <h1 className='fs-24 lh-30 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 fw-400 text-white mb-3 fw-700 font-sora'>
              Programa de pontos para sua empresa
            </h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-white mb-0'>
              Acumule pontos com o cartão de crédito e troque pelo benefício que mais combina com o seu negócio.
            </p>
            <S.Link
              target='_blank'
              rel='noreferrer'
              href={ctaUrl}
              className='fs-14 lh-17 fw-700 d-flex justify-content-center'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: 'Abrir conta PJ gratuita',
                  section_name: 'Chegou o programa de pontos para empresas que seu negócio precisa',
                  redirect_url: ctaUrl,
                })
              }}
            >
              Abrir conta PJ/MEI
            </S.Link>
            <ScrollTo
              to='#seu-programa-de-pontos'
              section='dobra_01'
              sectionName='Chegou o programa de pontos para empresas que seu negócio precisa'
              elementName='Quero ganhar pontos'
            >
              <S.Button className='fs-14 lh-17 fw-700 d-flex justify-content-center'>
                Como acumular pontos?
              </S.Button>
            </ScrollTo>
          </div>
          {
            width >= widths.md && (
              <div className='col-12 col-md-6'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/loop-hero-pj/image.webp'
                  altDescription='Logo Programa de Pontos Inter Loop' arrayNumbers={[ 366 ]} arrayNumbersHeight={[ 395 ]}
                />
              </div>

            )
          }
        </div>
      </div>
    </S.Section>
  )
}

export default HeroLoopPJ
