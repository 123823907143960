import { breakpoints } from "src/styles/breakpoints"
import styled from "styled-components"

export const Section = styled.section`
    background-color: #EBEBEB;    
    background-size: 360px;
    min-height: 1163px;

    @media (min-width: ${breakpoints.md}) {
        background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/loop-dobra2-pj/image.webp') no-repeat;
        background-position: left center;
        background-size: 358px;
        min-height: 697px;
        background-color: #EBEBEB;      
    }
    @media (min-width: ${breakpoints.lg}) {
        background-position: left center;
        background-size: 493px;
        min-height: 811px;
        background-color: #EBEBEB;      
    }


    @media (min-width: ${breakpoints.xl}) {
        background-position: left center;
        background-size: 657px;
        min-height: 871px;   
        background-color: #EBEBEB;   
    }

    .subtitle {
        margin-bottom: 56px;
    }

    h2 {
       margin-bottom: 16px;
       font-family: 'Citrina';
    }
    

`
