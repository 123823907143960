import styled from 'styled-components'
import { breakpoints, device } from 'src/styles/breakpoints'
import { laranja, white } from 'src/styles/colors'

export const Section = styled.section`
    background-color: ${laranja.terra};
    overflow: hidden;

    h2 {
      font-family: 'Citrina', Helvetica, sans-serif;
    }


    .carousel-container {
        overflow: visible;
    
        @media ${breakpoints.md} {
          overflow: hidden;
        }
      }

      .react-multiple-carousel__arrow--right {
        svg {
            fill: white;
        }

  }

  .react-multiple-carousel__arrow--left {
    svg {
            fill: white;
        }

  }

`

export const Card = styled.div`
   border: 1px solid ${white};
   border-radius: 8px;
   height: 212px;
   width: 100%;
   padding: 24px;
   background-color: transparent;   

   @media (min-width: ${breakpoints.md}){
    height: 216px;
    }


   @media (min-width: ${breakpoints.lg}){
    height: 236px;
    padding: 24px;
    margin-left: 24px;
    
   }

   @media (min-width: ${breakpoints.xl}){
    height: 217px;
    width: 264px;
   }

   .description {
    min-height: 95px;

    @media (min-width: ${breakpoints.lg}){
        min-height: 133px;
    }
   }

`
