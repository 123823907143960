import React from 'react'

import dataJson from './assets/data.json'

import { Section } from './styles'
import IcPaperMoney from '@interco/icons/core/finance/ic_paper_money'
import IcPiggyMoney from '@interco/icons/core/finance/ic_piggy_money'
import IcShoppingCartArrowDown from '@interco/icons/core/shopping/ic_shopping_cart_arrow_down'
import IcPlaneDeparture from '@interco/icons/core/travel-mobility/ic_plane_departure'
import IcCashback from '@interco/icons/core/finance/ic_cashback'
import { widths } from 'src/styles/breakpoints'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'

interface IIconsProps {
  icon: string;
  title: string;
  description: string;
}

interface IIconsList {
  [index: string]: React.ReactNode;
}

const ComoTrocarPontosInterLoop = () => {
  const width = useWidth(300)
  const icons: IIconsList = {
    paperMoney: <IcPaperMoney height={24} width={24} color='#FF7A00' />,
    piggyMoney: <IcPiggyMoney height={24} width={24} color='#FF7A00' />,
    shoppingCartArrowDown: <IcShoppingCartArrowDown height={24} width={24} color='#FF7A00' />,
    planeDeparture: <IcPlaneDeparture height={24} width={24} color='#FF7A00' />,
    cashback: <IcCashback height={24} width={24} color='#FF7A00' />,

  }

  const cards: IIconsProps[] = dataJson
  const FirstContent = () => {
    return (
      <div className='col-12'>
        <h2 className='fs-24 lh-28 fs-lg-32 lh-lg-38 fs-xl-40 fw-700 text-grayscale--500 text-center'>
          Como trocar os pontos Inter Loop?
        </h2>
        <p className='fs-16 lh-16 fs-lg-18 lh-lg-21 fw-400 text-grayscale--400 text-center subtitle'>
          Use seu cartão Inter Mastercard Microbusiness, acumule pontos e aproveite para trocá-los por benefícios para sua empresa
        </p>
      </div>
    )
  }

  const SecontContent = () => {
    return (
      <div className='col-12 col-md-6'>
        {
        cards.map((item: IIconsProps) => (

          <div className='mb-4' key={item.title}>
            <div className='d-flex align-items-center mb-3 mt-3'>
              {icons[item.icon]}
              <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-24 fw-600 mb-0 ml-2 text-orange--extra'>
                {item.title}
              </h3>
            </div>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-21 fw-400 text-grayscale--500'>
              {item.description}
            </p>
          </div>

        ))
      }
      </div>
    )
  }

  return (
    <Section className='py-5' role='img' aria-label='Super App Inter Empresas'>
      {
        width < widths.md ? (
          <>
            <div className='container'>
              <div className='row'>
                <FirstContent />
              </div>
            </div>
            <ImageWebp
              arrayNumbers={[]}
              altDescription='Super App Inter Empresas'
              arrayNumbersHeight={[]} pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/loop-dobra2-pj/image.webp'
            />
            <div className='container'>
              <SecontContent />
            </div>
          </>
        ) : (
          <div className='container'>
            <div className='row d-flex justify-content-end'>
              <FirstContent />
              <SecontContent />
            </div>
          </div>
        )
      }
    </Section>
  )
}

export default ComoTrocarPontosInterLoop
