import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import useUtms from 'src/hooks/useUtms'

import pageContext from './pageContext.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import {
  HeroLoopPJ,
  SeuProgramaDeFidelidadeParaEmpresas,
  ComoTrocarPontosInterLoop,
  AgoraSuaEmpresaPodeTransferirPontos,
  ComoAcumularPontos,
  OutrasFormasDeAcumular,
  SuaEmpresaNaoTemLimiteDeCredito,
  Faq,
} from './sections/_index'

const ProgramaDePontosParaEmpresas = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
      <HeroLoopPJ sendDatalayerEvent={sendDatalayerEvent} />
      <SeuProgramaDeFidelidadeParaEmpresas />
      <ComoTrocarPontosInterLoop />
      {/* <SuaEmpresaNaoTemLimiteDeCredito sendDatalayerEvent={sendDatalayerEvent} /> */}
      <AgoraSuaEmpresaPodeTransferirPontos />
      <ComoAcumularPontos />
      <OutrasFormasDeAcumular />
      <SuaEmpresaNaoTemLimiteDeCredito sendDatalayerEvent={sendDatalayerEvent} />
      <Faq faq={pageContext.structuredData.faq} />
    </Layout>
  )
}

export default ProgramaDePontosParaEmpresas
