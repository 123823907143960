import React from 'react'
import * as S from './styles'

// Icons
import ShoppingBag from '@interco/icons/build-v4/orangeds/MD/shopping-bag'
import Show from '@interco/icons/build-v4/orangeds/MD/show'
import Agenda from '@interco/icons/build-v4/orangeds/MD/agenda'

import data from './assets/data.json'
import { orange } from 'src/styles/colors'
import IcSparkle from '@interco/icons/core/action-navigation/ic_sparkle'

import { IconProps } from 'src/components/Layout/Footer/Footer.interface'
import { Item } from 'src/components/Autocomplete/SearchAutoComplete/style'

interface IData {
  icon: string;
  title: string;
}

const SeuProgramaDeFidelidadeParaEmpresas = () => {
  const icons: IconProps = {
    fourPointedStar: <IcSparkle height={32} width={32} color='#FF7A00' />,
    shoppingBag: <ShoppingBag width='32px' height='32px' color={orange.extra} />,
    show: <Show width='32px' height='32px' color={orange.extra} />,
    agenda: <Agenda width='32px' height='32px' color={orange.extra} />,
  }

  const cards: IData[] = data

  return (
    <S.Section
      className='py-5'
    >
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-28 fs-md-24 lh-md-28 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-48 fw-600 text-grayscale--500'>
              Garanta mais benefícios com o cartão Inter Empresas
            </h2>
            <p className='fs-14 lh-16 fs-lg-18 lh-lg-21 fw-400 text-grayscale--500 mb-md-0 mb-3'>
              Mais que cashback: aproveite todas as vantagens com o cartão de crédito do seu negócio.
            </p>
          </div>
          <div className='col-12 col-md-6 col-xl-4'>

            {cards.map((card: IData) => (
              <>
                <div className='d-flex align-items-center mb-3' key={Item.title}>
                  <div className='mr-3'>
                    {icons[card.icon]}
                  </div>
                  <h3
                    dangerouslySetInnerHTML={{ __html: card.title }}
                    className='fs-16 lh-19  fs-xl-20 lh-xl-24 fw-400 text-grayscale--500 mb-0'
                  />
                </div>
              </>
              ))}

          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SeuProgramaDeFidelidadeParaEmpresas
