import React from 'react'

import cardJson from './assets/data.json'

import { Section, Card } from './styles'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { white } from 'src/styles/colors'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

type CardProps = {
  title: string;
  description: string;
}

const AgoraSuaEmpresaPodeTransferirPontos = () => {
  const width = useWidth(300)

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-28 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-48 text-white fw-700 text-center mb-4'>
              Transfira pontos entre contas e destrave ainda mais benefícios
            </h2>
            <div className='col-12 d-flex justify-content-center'>
              {
                width >= widths.lg && (
                  cardJson.map((item: CardProps) => (
                    <Card key={item.title}>
                      <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-25 text-white fw-600 mb-2'>{item.title}</h3>
                      <h3 className='fs-14 lh-16 fs-lg-16 lh-lg-19 fw-400 text-white description mb-0'>{item.description}</h3>
                    </Card>
                  ))
                )
              }
            </div>
          </div>
          <div className='col-12'>

            {
              width <= widths.md && (

                <DefaultCarousel
                  sm={{ items: 1, partialVisibilityGutter: 90 }}
                  md={{ items: 3 }}
                  customDotColor={white}
                  containerClass='carousel-container'
                  itemClass='px-2'
                >
                  {
                  cardJson.map((item: CardProps) => (
                    <Card key={item.title}>
                      <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-25 text-white fw-600'>{item.title}</h3>
                      <h3 className='fs-14 lh-16 fs-lg-16 lh-lg-19 fw-400 text-white description mb-0'>{item.description}</h3>
                    </Card>
                  ))
                }
                </DefaultCarousel>

              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AgoraSuaEmpresaPodeTransferirPontos
