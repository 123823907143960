import { breakpoints } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }  


  p {
        margin-bottom: 31px;
    }

  @media (min-width: ${breakpoints.lg}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/loop-dobra4-pj/image.webp') no-repeat;
    background-position: 627px center;
    background-size: 469px;
    min-height: 486px;
  }

  @media (min-width: ${breakpoints.xl}){
    min-height: 608px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/loop-dobra4-pj/image.webp') no-repeat;
    background-position: right 168px;
    background-size: 681px;
    height: 400px;
  }


  @media (min-width: ${breakpoints.xxxl}){
    background-position: 996px 168px;
    }

`

export const BoxCard = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 0.5fr);
  gap: -5px;

  @media (min-width: ${breakpoints.md}){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: -5px;
  }

`

export const Card = styled.div`
  border: none;
  border-radius: 8px;
  background-color: #E49965;
  width: 100%;
  padding: 11px 24px 11px 24px;
  margin-bottom: 35px;
 
 
  

    @media (min-width: ${breakpoints.md}){
      width: 337px;
        padding: 11px 24px 11px 24px;
        
    }

    @media (min-width: ${breakpoints.lg}){
      width: 267px
    }


    @media (min-width: ${breakpoints.xl}){
      padding: 0px 24px 30px 24px;
      margin-bottom: 35px;
      
      
    }
    
    .description {
      margin-top: 10px;


      @media (min-width: ${breakpoints.md}) {
        min-height: 95px; 
      }
      
      @media (min-width: ${breakpoints.lg}){
        min-height: 95px;
        
      }

      @media (min-width: ${breakpoints.xl}) {
        min-height: 0;
      }
    }


`
export const Box = styled.div`
  background: #EA7100;
  border-radius: 4px;
  color: ${white};
  height: 28px;
  width: 277px;
  padding: 6px 16px;
  position: relative;
  top: -24px;
  left: -7px;;

  @media (min-width: ${breakpoints.md}){
    top: -25px;
    left: 6px;
  }

  @media (min-width: ${breakpoints.lg}){
    top: -21px;
    width: 247px;
    height: 29px;
    left: -15px;
  }

  @media (min-width: ${breakpoints.xl}){
    left: -14px;
    top: -12px;
  }
  
  p {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: ${white};
    text-align: center;
  }

`
export const Row = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.lg}) {
    justify-content: flex-start;
  }
`
