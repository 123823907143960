import React from 'react'

// Json
import cardJson from './assets/data.json'
import IcInterLoop from '@interco/icons/core/brands/ic_inter_loop'

import { Section, Card, Box, BoxCard, Row } from './style'
import ImageWebp from 'src/components/ImageWebp'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

type CardProps = {
    title: string;
    description: string;
    boxText: string;
}

const ComoAcumularPontos = () => {
    const width = useWidth(300)
    return (
      <Section
        className='py-5 d-flex align-items-center'
        id='seu-programa-de-pontos'
        role='img'
        aria-label='Cartão de crédito Inter Empresas'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='fs-24 lh-28 fs-md-24 lh-md-28 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-48 fw-700 text-grayscale--500 text-center mb-3'>
                Como acumular pontos com o cartão de crédito Inter Empresas?
              </h2>
              <p className='fs-14 lh-16 fs-lg-18 lh-lg-21 fw-400 text-grayscale--400 text-center mb-4'>
                Com o Inter Loop, os cartões PJ e MEI acumulam pontos a cada compra realizada no crédito
              </p>
              {
                width <= widths.md && (
                  <div className='col-12 '>
                    <ImageWebp
                      altDescription='Cartão de crédito Inter Empresas'
                      arrayNumbers={[ 317, 680 ]}
                      arrayNumbersHeight={[ 177, 400 ]}
                      pathSrc=' https://central-imagens.bancointer.com.br/images-without-small-versions/loop-dobra4-pj/image.webp'
                    />
                  </div>
                )
              }
            </div>
            <div className='col-12 col-lg-6 mt-4'>
              <Row className='row'>
                <BoxCard>
                  {
                    cardJson.map((item: CardProps, index: number) => (
                      <div className='col-12 col-md-6' key={item.description}>
                        <Card className={`cardItem-${index}`}>
                          <Box>
                            <p>{item.boxText}</p>
                          </Box>
                          <div className='d-flex align-items-center mb-2'>
                            <IcInterLoop color='#ffffff' width={24} height={24} />
                            <h3 className='fs-18 lh-21  fw-600 text-white mb-lg-0 mb-0 ml-2'>
                              {item.title}
                            </h3>
                          </div>
                          <h3 className='fs-14 lh-16 fs-lg-16 lh-lg-19 fs-xl-16 lh-xl-19 fw-400 text-white description  mb-0'>
                            {item.description}
                          </h3>
                        </Card>
                      </div>
                    ))
                }
                </BoxCard>
              </Row>
            </div>
          </div>
        </div>
      </Section>
    )
}

export default ComoAcumularPontos
