import React from 'react'
import cardJson from './assets/data.json'

import { Card } from './style'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import IcTarget from '@interco/icons/core/home-objects/ic_target'
import IcBuilding from '@interco/icons/core/travel-mobility/ic_building'
import IcTrendingUp from '@interco/icons/core/finance/ic_trending_up'
import IcShoppingBag from '@interco/icons/core/shopping/ic_shopping_bag'
import ContractMoney from '@interco/icons/core/files/ic_contract_money'

interface IIconsProps {
  icon: string;
  title: string;
  description: string;
}

interface IIconsList {
  [index: string]: React.ReactNode;
}

const OutrasFormasDeAcumular = () => {
    const width = useWidth(300)

    const icons: IIconsList = {
      target: <IcTarget height={24} width={24} color='#FF7A00' />,
      building: <IcBuilding height={24} width={24} color='#FF7A00' />,
      trendingUp: <IcTrendingUp height={24} width={24} color='#FF7A00' />,
      shoppingBag: <IcShoppingBag height={24} width={24} color='#FF7A00' />,
      contractMoney: <ContractMoney height={24} width={24} color='#FF7A00' />,
    }

    const cards: IIconsProps[] = cardJson

    return (
      <section className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='fs-20 lh-24 fs-lg-24 lh-lg-29 fw-600 text-grayscale--500 mb-4'>
                Outras formas de acumular pontos Loop
              </h2>
              {
                width <= widths.md && (
                  <DefaultCarousel
                    sm={{ items: 1 }}
                    md={{ items: 3, partialVisibilityGutter: 20 }}
                    containerClass='carousel-container'
                    itemClass='px-3'
                  >
                    {
                    cards.map((item: IIconsProps) => (
                      <Card key={item.icon} className='flex-row'>
                        {icons[item.icon]}
                        <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-24 fw-600 text-grayscale--500 mt-3'>{item.title}</h3>
                        <h3 className='fs-14 lh-16 fw-400 text-grayscale--400'>{item.description}</h3>
                      </Card>
                    ))
                }
                  </DefaultCarousel>
                )
              }
            </div>
            {
              width >= widths.lg && (
                <div className='col-12 d-flex'>
                  {
                      cards.map((item: IIconsProps) => (
                        <Card key={item.icon}>
                          {icons[item.icon]}
                          <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-24 fw-600 text-grayscale--500 mt-3'>{item.title}</h3>
                          <h3 className='fs-14 lh-16 fw-400 text-grayscale--400 description'>{item.description}</h3>
                        </Card>
                      ))
                  }
                </div>
              )
            }
          </div>
        </div>
      </section>
    )
}

export default OutrasFormasDeAcumular
