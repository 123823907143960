import { breakpoints } from "src/styles/breakpoints"
import styled from "styled-components"

export const Card = styled.div`
    border: 1px solid #DEDFE4;
    border-radius: 8px;
    background-color: transparent;
    height: 214px;
    width: 100%;
    padding: 24px;

    .carousel-container {
        overflow: visible;
    
        @media ${breakpoints.md} {
          overflow: hidden;
        }
      }


    @media (min-width: ${breakpoints.md}){
        height: 272px;
    }

    @media (min-width: ${breakpoints.lg}){
        height: 272px;
        margin-right: 24px;
    }

    @media (min-width: ${breakpoints.xl}){
        height: 272px;
        margin-right: 24px;
    }

    h3 {
        margin-bottom: 16px;
    }

    .description {
        min-height: 80px;
    }

    h2 {
        font-family: 'Citrina', Helvetica, sans-serif;
    }

`
