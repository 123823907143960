import styled from 'styled-components'
import { laranja, white } from 'src/styles/colors'
import { breakpoints, device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${laranja.terra};
  
  h1 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  @media (min-width: ${breakpoints.md}) {
    img {
      width: 366;
      height: 395;
    }

  }

 
`

export const Link = styled.a`
  height: 48px;
  width: 100%;
  background-color: ${white};
  color: ${laranja.terra};
  border-radius: 8px;
  padding: 16px 24px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 8px;
  }
  @media ${device.desktopLG} {
    margin-bottom: 16px;
  }
  @media ${device.desktopXL} {
    margin-bottom: 24px;
  }
  @media ${device.desktopXL} {
    margin-top: 40px;
  }
`

export const Button = styled.button`
  height: 48px;
  width: 100%;
  background-color: transparent;
  border: 1px solid ${white};
  color: ${white};
  border-radius: 8px;
  padding: 16px 24px;
  font-weight: 700;
  border: 1px solid #E5F2F1;
`
