import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

type PropsType = {
  color?: string;
}

type ActiveType = {
  active: boolean;
}

export const Select = styled.div`
  width: 98%;
  position: relative;
  border-radius: 8px;
  background: ${grayscale['100']};
  z-index: 11;
  margin: 0 auto 24px;

  @media ${device.tablet} {
    width: 100%;
  }
`
export const SelectHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 50px 0 16px; 
  cursor: pointer;

  span {
    max-height: 48px;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 30px;
  }

  svg {
    position: absolute;
    left: 17px;
  }
`
export const Option = styled.div`
  position: absolute;
  display: ${(props: ActiveType) => props.active ? 'block' : 'none'};
  width: 100%;
  height: ${(props: ActiveType) => props.active ? '328px' : '0'};
  padding: ${(props: ActiveType) => props.active ? '16px' : '0'};
  overflow: hidden;
  border: 1px solid ${(props: PropsType) => props.color || '#FFDAB7'};
  border-radius: 8px;
  z-index: 1;
  background: #F5F6FA;
  top: 0;
  transition: all .3s ease-in-out;
`
export const Item = styled.div`
  display: block;
  width: 100%;
`
export const Input = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
  height: 48px;
  
  input {
    background-color: ${white};
    width: 98%;
    border: none;
    border-radius: 8px;
    outline: none;
    padding: 0 50px 0 16px;
    height: 48px;
    margin-left: 1%;

    @media ${device.tablet} {
      padding: 0 16px;
    }
    
    &:focus {
      outline: none;
    }
  }

  button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    @media ${device.tablet} {
      right: 20px;
    }
    @media ${device.desktopXL} {
      right: 25px;
    }
    @media ${device.desktopXXXL} {
      right: 30px;
    }
    
    &:active {
      outline: none;
    }
  }

  ul {
    padding:  ${(props: ActiveType) => props.active ? '2px 15px' : '0'};
    max-height: ${(props: ActiveType) => props.active ? '198px' : '0'};
    overflow: auto;
    width: 99%;
    margin-top: 10px;

    li {
      padding: 10px 0;
      min-height: 48px;
      font-size: 16px;
      line-height: 22px;
      border-bottom: 1px solid ${white};
      justify-content: center;
      display: flex;
      flex-direction: column;

      &:hover {
        cursor: pointer;
      }
    }
  }
`

export const OpacitySectionContainer = styled.div`
  position: fixed;
  background: transparent none;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
`
